.chart {
  margin: 0 64px;
  display: flex;
  flex-direction: column;
  font-family: "Asul", sans-serif;
  .details {
    display: flex;
    justify-content: center;
  }
  .list {
    padding: 16px;
    margin: 0;
    font-size: 32px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    li:first-of-type {
      margin: 24px 0 0 0;
    }
    li:last-of-type {
      border: unset;
    }
  }
}

@media only screen and (max-width: 992px) {
  .chart {
    margin: 0 16px;
  }
}
