.row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff;
  margin: 32px 0 0 0;
  padding: 0 16px 16px;
}

li.row > div > p.note {
  margin-block-start: none;
  margin-block-end: none;
  font-size: medium;
}
